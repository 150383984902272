<template>
    <div class="container-fluid main-color">
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <router-link :to="{
                            name: 'program-handover-approval',
                        }">
                            <button type="button" class="btn btn-sm btn-blue waves-effect waves-light mb-2">
                                <i class="mdi mdi-arrow-left"></i>
                                Back
                            </button>
                        </router-link>
                    </div>
                    <h4 class="page-title">Handover Approval Management</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-10">
                <place-holder v-if="loading"></place-holder>
                <div class="card">
                    <div v-if="!loading" class="card-body">
                        <form class="parsley-examples">
                            <div class="row mb-3">
                                <label for="name" class="col-md-3 form-label">
                                    Program Name
                                </label>
                                <div class="col-md-7">
                                    <input type="text" id="name" name="name" class="form-control" placeholder="Program Name"
                                        v-model="approvalLists.program_name" disabled />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="issued_date" class="col-md-3 form-label">
                                    Issued Date
                                </label>
                                <div class="col-md-7">
                                    <input type="text" id="date" name="date" class="form-control" placeholder="Issued Date"
                                        v-model="handover_approval.issued_date" disabled />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="issued_date" class="col-md-3 form-label">
                                    Trainer From
                                </label>
                                <div class="col-md-7">
                                    <input type="text" id="date" name="date" class="form-control" placeholder="Issued Date"
                                        v-model="handover_approval.trainer_from.name" disabled />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="issued_date" class="col-md-3 form-label">
                                    Trainer To
                                </label>
                                <div class="col-md-7">
                                    <input type="text" id="date" name="date" class="form-control" placeholder="Issued Date"
                                        v-model="handover_approval.trainer_to.name" disabled />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="requested" class="col-md-3 form-label">
                                    Requested By
                                </label>
                                <div class="col-md-7">
                                    <input type="text" id="requested" name="requested" class="form-control"
                                        placeholder="Requested By" v-model="approvalLists.requested_by.name" disabled />
                                </div>
                            </div>

                            <div class="row mb-3">
                                <label for="remark" class="col-md-3 form-label">
                                    Remark
                                </label>
                                <div class="col-md-7">
                                    <textarea name="remark" id="remark" rows="2" placeholder="Remark"
                                        v-model="handover_approval.remark" class="form-control" disabled></textarea>
                                </div>
                            </div>
                            <!-- end row -->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="text-center mt-3 mb-3 d-flex justify-content-center">
                                        <div>
                                            <!-- <router-link v-if="status == 'Requested'"
                                                :to="{ name: 'program-handover-approval' }">
                                                <button type="button"
                                                    class="btn w-sm btn-success waves-effect waves-light me-5"
                                                    @click="updateModuleHandoverApproval(status = 'Approved')">
                                                    Approved
                                                </button>
                                            </router-link> -->
                                            <button type="button" v-if="status == 'Requested'"
                                                class="btn w-sm btn-success waves-effect waves-light me-5"
                                                @click="showModal(status_detail = 'Approved')">
                                                Received
                                            </button>
                                            <button v-else type="button" style="cursor: not-allowed"
                                                data-bs-toggle="tooltip"
                                                title="You cannot change status after Received or Declined!"
                                                class="btn w-sm btn-success waves-effect waves-light me-5">
                                                Received
                                            </button>
                                        </div>
                                        <div>
                                            <!-- <router-link v-if="status == 'Requested'"
                                                :to="{ name: 'program-handover-approval' }">
                                                <button type="button" class="btn w-sm btn-danger px-3"
                                                    @click="updateModuleHandoverApproval(status = 'Rejected')">
                                                    Rejected
                                                </button>
                                            </router-link> -->
                                            <button type="button" v-if="status == 'Requested'"
                                                class="btn w-sm btn-danger px-3"
                                                @click="showModal(status_detail = 'Rejected')">
                                                Declined
                                            </button>
                                            <button v-else type="button" style="cursor: not-allowed"
                                                data-bs-toggle="tooltip"
                                                title="You cannot change status after Received or Declined!"
                                                class="btn w-sm btn-danger px-3">
                                                Declined
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <EnrollModal :modalId="'confirm-modal'">
        <template v-slot:header> Confirmation </template>
        <template v-slot:body>
            <div class="text-center" v-if="!loading">
                <p v-if="status_detail == 'Approved'" class="text-center fs-4"><span class="text-success"> Are you Received
                        ? </span>
                </p>
                <p v-else class="text-center fs-4"><span class="text-danger"> Are you Declined ? </span></p>
                <div v-if="status_detail == 'Rejected'" class="row my-3">
                    <label for="reason" class="col-md-3 form-label">
                        Reason
                    </label>
                    <div class="col-md-7">
                        <textarea name="reason" id="reason" rows="2" placeholder="Please add Reason"
                            v-model="declined_reason" class="form-control" :class="{
                                'p-invalid':
                                    v$.declined_reason.$error || errorFor('reason'),
                            }"></textarea>
                        <v-errors :serverErrors="errorFor('reason')" :vuelidateErrors="{
                            errors: v$.declined_reason.$errors,
                            value: 'Reason',
                        }"></v-errors>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn w-sm btn-secondary me-4" data-bs-dismiss="modal">
                Close
            </button>
            <button :disabled="isLoading ? true : false" type="button" class="btn w-sm btn-success waves-effect waves-light"
                @click="updateModuleHandoverApproval(status_detail)">
                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ isLoading == true ? 'Loading...' : 'Confirm' }}
            </button>
        </template>
    </EnrollModal>
</template>
  
<script>
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";
import EnrollModal from "../../components/shared/Modal.vue";

export default {
    setup() {
        const toast = useToast();
        return { v$: useVuelidate(), toast };
    },
    components: {
        EnrollModal,
    },
    mixins: [validationErrors],
    data() {
        return {
            approvalLists: "",
            handover_approval: {
                issued_date: "",
                trainer_from: "",
                trainer_to: "",
                trainer_to_user_id: "",
                remark: "",
            },
            trainer_from: "",
            trainer_to: "",
            status: "",
            status_detail: "",
            declined_reason: "",
            baseUrl: process.env.VUE_APP_BASE_URL,
            loading: false,
            isLoading: false
        };
    },
    validations() {
        return {
            declined_reason: { required },
        };
    },
    methods: {
        async getModuleApprovalById() {
            this.loading = true;
            await axios
                .get(`${this.baseUrl}admin/v1/program-handover-approval/${this.$route.params.id}`)
                .then((response) => {
                    const result = response.data.data;
                    this.approvalLists = result;
                    this.handover_approval.issued_date = result.issued_date;
                    this.handover_approval.trainer_from = result.trainer_from;
                    this.handover_approval.trainer_to = result.trainer_to;
                    this.handover_approval.remark = result.remark;
                    this.status = result.status;
                    this.selected_trainer = result.selected_trainer;
                })
                .catch(() => {
                    this.toast.error("Not Found Program!");
                });
            this.loading = false;
        },

        showModal(status_detail) {
            console.log(status_detail);
            $("#confirm-modal").modal("show");
        },

        async updateModuleHandoverApproval(status) {
            if (status == "Rejected") {
                const isFormCorrect = await this.v$.$validate();
                if (!isFormCorrect) return;
            }
            this.handover_approval.program_owner_id = this.approvalLists.program_owner_id;
            this.handover_approval.handover_id = this.$route.params.id;
            this.handover_approval.trainer_from = this.handover_approval.trainer_from.employee_id;
            this.handover_approval.trainer_to_user_id = this.handover_approval.trainer_to.id;
            this.handover_approval.trainer_to = this.handover_approval.trainer_to.employee_id;
            this.handover_approval.changed_status_by = this.$store.state.auth.user.employee_id;
            this.handover_approval.status = status;
            if (status == "Rejected") {
                this.handover_approval.declined_reason = this.declined_reason;
            }
            this.isLoading = true;
            axios
                .post(
                    `${this.baseUrl}admin/v1/save-program-handover`, {
                    programs: [this.handover_approval],
                    issued_date: this.handover_approval.issued_date,
                    is_created: 0,
                    selected_trainer: this.selected_trainer
                }
                )
                .then(() => {
                    $("#confirm-modal").modal("hide");
                    this.$router.push({ name: "program-handover-approval" });
                    this.toast.success("Successfully Status Changed!");
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.toast.error("Something Went Wrong!");
                    this.isLoading = false;
                });
        },
    },
    created() {
        this.getModuleApprovalById();
    },
};
</script>

<style>
.form-control:disabled,
.form-control[readonly] {
    background-color: #efefef;
    opacity: 1;
    cursor: not-allowed;
}
</style>